<style lang="scss" scoped>
.color-grey {
    color: #c0c4cc;
}

</style>
<template>
    <div>
        <et-table :columns="columns" :dataList="list" :show-buttons="true" :is-index="true">
            <template v-slot:buttons="slotRow">
                <time-expand-dialog v-if="userInfo.userType==='0'" :info="slotRow.row"></time-expand-dialog>
                <enable-button v-if="userInfo.userType==='0'" :info="slotRow.row"></enable-button>
                <et-logs :info="slotRow.row"></et-logs>
            </template>
        </et-table>
    </div>
</template>

<script type="text/ecmascript-6">
import data from './data'
import Table from "@/www/components/table/index"
import TimeExpandDialog from '../dialog/time-expand-dialog.vue'
import EnableButton from '../enable-button/index.vue'
import EtLogs from '../logs/index.vue'

export default {
    //定义模版数据
    data() {
        return {
            data,
            dialogIndex: null,
            currentData: {}
        }
    },
    components: {
        EtTable: Table,
        TimeExpandDialog,
        EnableButton,
        EtLogs
    },
    //计算属性
    computed: {
        userInfo() {
            return this.$store.state.loginUserInfo
        },
        list() {
            return this.$store.getters.getVehicleDriverList
        },
        columns() {
            const c = [...data.columns];
            if (this.$store.state.vehicleDriver.productType === 1) {
                const i = c.splice(0, 1);
                c.unshift(c[3])
                c[4] = i[0];
            }
            console.log(c);
            return c;
        }
    }
}
</script>

