<style lang="scss" scoped>

.date-picker {
    margin-top: 10px;
}
</style>
<template>
    <div>
        <el-button type="text" @click="handleClick">
            有效期延长
        </el-button>
        <et-dialog :isShow="show"
                   title="有效期延长"
                   isShowFooter
                   :width="440"
                   @update:cancelBtn="handleCancel"
                   @update:submitBtn="handleSubmit">
            <div slot="content">
                <el-form :model="form" ref="form">
                    <div style="color: #909399;margin-bottom: 15px">
                        <p>原有效期：</p>
                        <p>
                            {{ info.validityStartTime | dateCus('yyyy-MM-dd hh:mm') }}
                            <i class="el-icon-arrow-right" style="font-size: 12px"></i>
                            {{ info.validityEndTime | dateCus('yyyy-MM-dd hh:mm') }}
                        </p>
                    </div>
                    <div>
                        <p>现有效期：共延长{{ diffText }}</p>
                        <el-form-item :rules="[{require:true,message:'请选择结束时间'}]" prop="validityTime">
                            <div class="date-picker">
                                <el-date-picker v-model="form.validityEndTime"
                                                placeholder="选择结束时间"
                                                @change="handleDateChange"
                                                format="yyyy-MM-dd 23:59:59"
                                                range-separator=">"
                                                :picker-options="pickerOptions">
                                </el-date-picker>
                            </div>
                        </el-form-item>

                    </div>
                </el-form>
            </div>
        </et-dialog>
    </div>
</template>

<script type="text/ecmascript-6">
import moment from 'moment'
import EtDialog from "@/www/components/dialog/index"
import dayjs from "dayjs";

export default {
    //定义模版数据
    data() {
        return {
            form: {
                validityEndTime: null
            },
            diffText: '0天',
            diffNumber: '0',
            pickerOptions: {
                disabledDate: (date) => {
                    return moment(date).valueOf() < moment(this.info.validityEndTime).valueOf()
                }
            },
            show: false
        }
    },
    props: {
        info: {
            type: Object,
            default: () => ({})
        }
    },
    components: {
        EtDialog,
    },
    //定义事件方法
    methods: {
        //时间改变
        handleDateChange() {
            let m1 = moment(this.info.validityEndTime)
            let m2 = moment(this.form.validityEndTime).endOf('d')
            let diff = m2.diff(m1, 'days');
            let unit = '天'
            if (diff < 1) {
                diff = m2.diff(m1, 'hour')
                unit = '小时'
            }
            if (diff < 1) {
                diff = m2.diff(m1, 'minute')
                unit = '分钟'
            }
            this.diffNumber = diff;
            this.diffText = `${diff}${unit}`
        },
        //取消
        handleCancel() {
            this.show = false
        },
        //确定
        handleSubmit() {
            this.$refs['form'].validate(async (valid) => {
                if (valid) {
                    if (this.diffNumber > 0) {
                        const ret = await this.$store.dispatch('update_recharge_driver_info', {
                            driverId: this.$store.state.vehicleDriver.productType === 0 ? this.info.driverId : undefined,
                            carId: this.$store.state.vehicleDriver.productType === 1 ? this.info.carId : undefined,
                            validityEndTime: dayjs(this.form.validityEndTime).endOf('d').valueOf()
                        })
                        if (ret) {
                            this.show = false
                            let form = {}
                            this.$store.dispatch('search_vehicle_driver_list', {pageIndex: 1, form})
                        }
                    } else this.show = false
                }
            });
        },
        validator: (rule, value, callback) => {
            if (value.length < 2)
                return callback('请选择开始和结束时间')
            callback()
        },
        //点击显示
        handleClick() {
            this.show = true
        }
    },
    watch: {
        info: {
            immediate: true,
            handler() {
                this.form.validityEndTime = this.info.validityEndTime
            }
        }
    }
}
</script>

