const columns = [
    {label: "司机", prop: "driver", width: '180'},
    {label: "运营商", prop: "operatorName"},
    {label: "绑定机构", prop: "supplierName"},
    {label: "车型", prop: "carTypeName", width: '90'},
    {label: "车牌号", prop: "carNumberPlate", width: '100'},
    {label: "服务类型", prop: "productName", width: '100'},
    {label: "充值类型", prop: "rechargeTypeName", width: '80'},
    {label: "起始时间", prop: "validityStartTime", width: '140'},
    {label: "截止时间", prop: "validityEndTime", width: '140'},
    {label: "状态", prop: "statusName", width: '80'}
];
export default {
    columns
}
