<style lang="scss" scoped>
.container {
    background-color: #f8f8f8;
}

.form-inline {
    padding-top: 10px;
    background-color: #fff;
    padding-left: 16px;
    margin-bottom: 10px;
}

.pagination-container {
    text-align: right;
    margin-top: 10px;
}

.warning-color {
    color: #FF3B30;
}
</style>
<template>
    <div>
        <el-main class="com-eTrip-section section-has-footer">
            <div class="eTrip-section-cont">
                <search ref="rechargeProductsSearchRef"></search>
                <content-table style="margin-top: 10px"></content-table>
            </div>
        </el-main>
        <footer-pagination :pageInfo="page" @update:initData="paginationChange">
        </footer-pagination>
    </div>

</template>

<script type="text/ecmascript-6">
import Search from "./components/search/index"
import ContentTable from "./components/table/index"
import footerPagination from "@/www/components/footerPagination/index"

export default {
    //计算属性
    computed: {
        page() {
            return this.$store.getters.getVehicleDriverPage
        },
        path() {
            return this.$route.path
        }
    },
    components: {
        Search,
        ContentTable,
        footerPagination
    },
    methods: {
        paginationChange(pageIndex) {
            this.$store.dispatch('search_vehicle_driver_list', {pageIndex})
        }
    },
    watch: {
        path: {
            handler(n) {
                if (n === '/vehicleDriverList')
                    this.$store.commit('change_vehicle_driver_list_productType', 0)
                if (n === '/vehicleCarList')
                    this.$store.commit('change_vehicle_driver_list_productType', 1)
            },
            immediate: true,
        }
    }
}
</script>
