<style lang="scss" scoped>
.recharge-products-search /deep/ {
    .common-list-search {
        .list-search-before {
            width: calc(100% - 100px);

            .el-form-item.el-form-item--small {
                width: auto;
            }

            .el-form-item__content {
                width: 160px;
            }
        }

        .list-search-after {
            width: 160px;
        }
    }
}

</style>
<template>

    <div class="recharge-products-search">
        <com-list-search>
            <div slot="list-search-before">
                <el-form :inline="true" :model="form" size="small">
                    <el-form-item label="司机" v-if="$store.state.vehicleDriver.productType===0">
                        <el-input v-model="form.driverName" placeholder="司机姓名" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="车辆" v-else>
                        <el-input v-model="form.carNumberPlate" placeholder="车牌号" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="运营商"
                                  v-if="userInfo.userType==='0'">
                        <operator-select v-model="form.operatorId"></operator-select>
                    </el-form-item>
                    <el-form-item label="绑定机构"
                                  v-if="userInfo.userType!=='1'">
                        <supplier-select :operatorId="form.operatorId" v-model="form.supplierId"></supplier-select>
                    </el-form-item>
<!--                    <el-form-item label="服务类型">-->
<!--                        <et-select :options="rechargeProductTypes" v-model="form.productType"></et-select>-->
<!--                    </el-form-item>-->
                    <el-form-item label="充值类型">
                        <et-select :options="rechargeTypeList" v-model="form.rechargeType"></et-select>
                    </el-form-item>
                    <el-form-item label="状态">
                        <et-select :options="enableStatusList" v-model="form.isDelete"></et-select>
                    </el-form-item>
                    <el-form-item label="截止时间">
                        <et-select :options="deadlineList" v-model="form.validityEndTime"></et-select>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="list-search-after">
                <el-button @click="resetForm()">重置</el-button>
                <el-button type="primary" @click="search(1)">搜索</el-button>
            </div>
        </com-list-search>
    </div>

</template>

<script type="text/ecmascript-6">
import comListSearch from '@/www/components/comListSearch/index.vue'
import operatorSelect from "@/www/components/operator-select/index"
import supplierSelect from "@/www/components/supplier-select/index"
import {initForm} from '@/www/vuex/modules/vehicleDriver'
import EtSelect from '@/components/et-select/index'
import {rechargeProductTypes} from "@/data";

export default {
    //定义模版数据
    data() {
        return {
            rechargeProductTypes,
            form: {
                ...initForm
            }
        }
    },
    computed: {
        userInfo() {
            return this.$store.state.loginUserInfo;
        },
        rechargeTypeList() {
            return this.$store.state.vehicleDriver.rechargeTypeList
        },
        enableStatusList() {
            return this.$store.state.vehicleDriver.enableStatusList
        },
        deadlineList() {
            return this.$store.state.vehicleDriver.deadlineList
        },
        productType() {
            return this.$store.state.vehicleDriver.productType
        }
    },
    components: {
        comListSearch,
        operatorSelect,
        supplierSelect,
        EtSelect
    },
    mounted() {
        this.search(1)
    },
    //定义事件方法
    methods: {
        search(pageIndex) {
            let form = JSON.parse(JSON.stringify({...this.form}));
            this.$store.dispatch('search_vehicle_driver_list', {pageIndex, form})
        },
        resetForm() {
            this.form = {...initForm};
            this.$store.commit('rest_vehicle_driver_form')
        }
    },
    watch: {
        productType() {
            this.$store.dispatch('search_vehicle_driver_list', {
                pageIndex: this.$store.getters.getVehicleDriverPage.pageIndex
            })
        }
    }
}
</script>

