<template>
    <el-select :value="value"
               :clearable="clearable"
               v-bind="$attrs"
               @change="handleChange">
        <el-option :value="vm.value"
                   :label="vm.label"
                   v-for="vm in options"
                   :key="vm.value">
        </el-option>
    </el-select>
</template>

<script type="text/ecmascript-6">
export default {
    props: {
        value: {
            type: [String, Number, Boolean],
            default: null
        },
        options: {
            type: Array,
            default: () => []
        },
        clearable: {
            type: Boolean,
            default: true
        },
    },
    methods: {
        handleChange(val) {
            this.$emit('input', val)
        }
    }

}
</script>
