<style lang="scss" scoped>
</style>
<template>
    <el-button type="text" @click="handleClick" :class="info.isDelete === 1?'color-danger':''">
        {{ info.isDelete === 0 ? '封停' : '启用' }}
    </el-button>
</template>

<script type="text/ecmascript-6">
import {rechargeProductTypes} from "@/data";
import {findItem} from '@/common/js/common'

export default {
    //定义模版数据
    data() {
        let data
        return {
            data
        }
    },
    props: {
        info: {
            type: Object,
            default: () => ({})
        }
    },
    //定义事件方法
    methods: {
        async handleClick() {
            try {
                const msg = this.info.productType === 0 ? `>确认${this.info.isDelete === 0 ? '封停' : '启用'}以下司机的车辆服务业务？` : `确认封停车辆沪${this.info.carNumberPlate}技术服务功能？`
                await this.$confirm(`<div>
<p>${msg}</p>
<p>司机信息：${this.info.driver}</p>
<p>服务类型：${findItem(rechargeProductTypes, this.info.productType)}</p>
</div>`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    dangerouslyUseHTMLString: true
                })
                this.$store.dispatch('enable_recharge_driver_info', {
                    isDelete: this.info.isDelete === 0 ? 1 : 0,
                    driverId: this.info.productType === 0 ? this.info.driverId : undefined,
                    carId: this.info.productType === 1 ? this.info.carId : undefined
                })
                let form = {};
                this.$store.dispatch('search_vehicle_driver_list', {pageIndex: 1, form})
            } catch (e) {
                console.log(e);
            }
        }
    }
}
</script>
