<style lang="scss" scoped>
.list-item {
    /*display: flex;*/
    justify-content: space-between;
    margin-bottom: 10px;
}
</style>
<template>
    <el-popover placement="left"
                title="操作日志"
                width="400"
                trigger="click">
        <ul v-loading="loading" style="overflow: hidden;overflow-y: scroll;max-height: 280px">
            <li v-for="vm in logs" :key="vm.id" class="list-item">
                <p>修改时间：{{ vm.auditTime | dateCus }}</p>
                <p>修改内容：{{ vm.auditInfo }}</p>
            </li>
        </ul>
        <el-button type="text" slot="reference" @click="handleClick">日志</el-button>
    </el-popover>
</template>

<script type="text/ecmascript-6">
export default {
    //定义模版数据
    data() {
        return {
            content: null,
            loading: false
        }
    },
    props: {
        info: {
            type: Object,
            default: () => ({})
        }
    },
    //计算属性
    computed: {
        logs() {
            return this.$store.state.vehicleDriver.logs
        }
    },
    //定义事件方法
    methods: {
        async handleClick() {
            this.loading = true
            await this.$store.dispatch('get_recharge_log_list', {
                driverId: this.info.productType === 0 ? this.info.driverId : undefined,
                carId: this.info.productType === 1 ? this.info.carId : undefined,
            })
            this.loading = false

        }
    }

}
</script>
